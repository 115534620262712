<template>
  <v-container fluid class="px-3 py-4" style="height: 100%; display: flex; flex-direction: column">
    <div class="d-flex align-center mb-2" style="flex: none">
      <h2 class="text--primary font-weight-black mr-4">
        <i class="fad fa-user-cog mr-2" style="margin-left: 4px"></i>Personal Settings
      </h2>
      <v-btn
        class="ml-2"
        :disabled="isSaving || !hasChanges"
        @click="discardChanges()"
        color="secondary"
      >
        <i class="fal fa-clock-rotate-left mr-2"></i> Discard
      </v-btn>

      <div class="d-flex ml-2">
        <v-tooltip top z-index="999" nudge-top="-4px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="info"
              :disabled="isSaving || !hasChanges || !valid"
              @click="updateConfirmed()"
              :loading="isSaving"
            >
              <i class="mr-2 fal fa-plus"></i>
              {{ "Save" }}
            </v-btn>
          </template>
          <span class="d-flex align-center"
            >"Save"
            <kbd class="light ml-1 fs-12px">CTRL<span class="opacity-54">+</span>S</kbd>
          </span>
        </v-tooltip>
      </div>
    </div>
    <v-card class="ma-0">
      <v-scroll-y-transition mode="out-in">
        <v-container key="fetching" fluid class="text-center" v-if="isFetching">
          <video width="320" muted loop autoplay style="padding: 0.5rem; margin: 0 auto">
            <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
          </video>
          <p class="font-weight-bold">Fetching your personal settings ...</p>
        </v-container>
        <v-container
          key="settings-loaded"
          fluid
          v-else-if="loadingError"
          class="d-flex flex-column align-center justify-center py-12"
        >
          <img width="500" style="max-width: 100%" src="/img/art/undraw_moonlight_-5-ksn.svg" />
          <h1 class="mt-3" style="font-size: 2.75rem; color: #e45d51">
            Error Loading Your Personal Settings!
          </h1>
          <h3 class="font-weight-bold text--disabled" style="font-size: 1.5rem">
            Uh-Oh! Something went wrong!
          </h3>
        </v-container>
        <v-container class="pa-2" key="settings-loaded" fluid v-else>
          <v-form v-model="valid" :disabled="isSaving">
            <v-container fluid class="pb-6">
              <h3 class="font-weight-black">Projects & Spaces</h3>
              <v-divider class="my-2"></v-divider>
              <v-switch
                class="ma-0 pa-0 v-switch-align-top"
                color="orange darken-1"
                hide-details
                dense
                v-model="selected.autoCreateDefaultAreaGroupInSpace"
              >
                <template v-slot:label>
                  <div class="d-flex flex-column pl-2">
                    <label class="font-weight-bold fs-15px secondary--text ma-0">
                      <!-- When creating a new space, a default area group will be auto created! -->
                      Auto create a default Area Group!
                    </label>
                    <p class="font-weight-medium text--secondary ma-0 fs-12px">
                      When creating a new Space, Concordia will auto create a default Area Group!
                    </p>
                  </div>
                </template>
              </v-switch>
            </v-container>
            <v-container
              fluid
              class="pb-6"
              v-if="$has(perms.PersonalSettings.ShowManagementSettings)"
            >
              <h3 class="font-weight-black mt-2">Users</h3>
              <v-divider class="my-2"></v-divider>
              <v-switch
                class="ma-0 pa-0 v-switch-align-top"
                color="orange darken-1"
                hide-details
                dense
                v-model="selected.maskSuspendedUsers"
              >
                <template v-slot:label>
                  <div class="d-flex flex-column pl-2">
                    <label class="font-weight-bold fs-15px secondary--text ma-0">
                      Mask Suspended Users
                    </label>
                    <p class="font-weight-medium text--secondary ma-0 fs-12px">
                      When turned off, suspended users will appear as normal users all over
                      Concordia.<br />
                      i.e. suspended users will be appear and be searchable on any users list<br />
                      and appear without the
                      <u class="grey--text text--darken-1 mr-1">Previous User</u>mask
                    </p>
                  </div>
                </template>
              </v-switch>
            </v-container>
          </v-form>
        </v-container>
      </v-scroll-y-transition>
    </v-card>
  </v-container>
</template>

<script>
import ApiService from "../services/personal-settings-service";
import perms from "../../../plugins/permissions";

export default {
  components: {},
  data() {
    return {
      perms,
      isEditing: false,
      selected: { autoCreateDefaultAreaGroupInSpace: false, maskSuspendedUsers: false },
      selectedCemented: null,
      hasChanges: false,
      isFetching: false,
      isSaving: false,
      loadingError: false,
      valid: true,
    };
  },
  created() {
    this.fetchPersonalSettings();
  },
  watch: {
    selected: {
      handler() {
        this.checkForChanges();
      },
      deep: true,
    },
  },
  methods: {
    fetchPersonalSettings() {
      this.isFetching = true;
      this.loadingError = false;
      ApiService.get()
        .then((res) => {
          if (res == null) return;
          this.selected = res.data;
          this.selectedCemented = this.cloneDeep(this.selected);
          this.isFetching = false;
        })
        .catch((err) => {
          this.isFetching = false;
          this.loadingError = true;
          // this.$handleError(err);
        });
    },
    checkForChanges() {
      this.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      if (this.hasChanges) {
        this.$log(
          "%c=>> deepDiff",
          "color: red",
          this.deepDiff(this.selectedCemented, this.selected)
        );
      }

      if (this.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    updateConfirmed() {
      this.isSaving = true;
      ApiService.update(this.selected)
        .then((resp) => {
          this.$store.dispatch("setPersonalSettings", this.cloneDeep(resp.data));
          this.selected = resp.data;
          this.selectedCemented = this.cloneDeep(this.selected);
          this.isSaving = false;
        })
        .catch((err) => {
          this.isSaving = false;
          this.$handleError(err);
        });
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
    },
  },
  computed: {},
};
</script>

<style></style>
